@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  overscroll-behavior: none;
}
html {
  overscroll-behavior: none;
}
@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
/* Fonts setup */

@font-face {
  font-family: "AvenirLTMedium", sans-serif;
  src: url("../src/fonts/WebView/AvenirLTProMedium.otf");
  font-weight: 600;
}

@font-face {
  font-family: "AvenirLTLight", sans-serif;
  src: url("../src/fonts/WebView/AvenirLTProLight.otf");
  font-weight: 400;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "AvenirLTLight", sans-serif;
}

@keyframes slideInY {
  0% {
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideOutY {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(200%);
    opacity: 0;
  }
}

.an‌​imate-slide-y-in {
  animation: slideInY 10s ease-in-out;
}

.an‌​imate-slide-y-out {
  animation: slideOutY 10s ease-in-out;
}

body::-webkit-scrollbar {
  display: none;
}

.button-bounce:active {
  animation: press 0.2s 1 linear;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@keyframes bounce {
  50% {
    transform: rotate(5deg) translate(20px, -50px);
  }
  to {
    transform: scale(0.9) rotate(10deg) translate(50px, -80px);
    opacity: 0;
  }
}
@keyframes press {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.92);
  }
  to {
    transform: scale(1);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ffaa1e !important;
}

.ant-tabs-ink-bar {
  background: #ffaa1e !important;
}

.ant-tabs-tab:hover {
  color: #ffaa1e !important;
}

@keyframes spin-custom {
  0% {
    background: #efc37d;
  }
  50% {
    background: #ffaa1e;
  }
  100% {
    background: #d78602;
  }
}

.text-white {
  color: white !important;
}
.background-yellow {
  background: #ffaa1e !important;
}

.ant-tabs-nav-operations {
  display: none !important;
}

.card-img img {
  border: inherit;
  border-radius: inherit;
  object-fit: cover !important;
  height: 100% !important;
  width: 100% !important;
}

/* ///////////////// ant collapse css custome */

.antdCustomeCollapseCss > div {
  padding: 0 !important;
}
.ant-collapse-expand-icon {
  display: flex !important;
  align-self: center !important;
}
.ant-collapse-content > .ant-collapse-content-box {
  padding: 0 !important;
}
