.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.background-image-category {
  background-image: url("/public/MobileViewImages/categoryImgNotFound.png");
}

/* Loader  */
.loader {
  font-size: 10px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  animation: mulShdSpin 1.1s infinite ease;
  transform: translateZ(0);
}
/* @keyframes mulShdSpin {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff,
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7),
      1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff,
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff,
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.5),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff,
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff,
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff,
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}  */

/* .loader-ai {
  font-size: 10px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  animation: mulShdSpin 1.1s infinite ease;
  transform: translateZ(0);
} */

@keyframes mulShdSpin {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff,
      1.8em -1.8em 0 0em rgba(255, 170, 30, 0.2),
      2.5em 0em 0 0em rgba(255, 170, 30, 0.2),
      1.75em 1.75em 0 0em rgba(255, 170, 30, 0.2),
      0em 2.5em 0 0em rgba(255, 170, 30, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 170, 30, 0.2),
      -2.6em 0em 0 0em rgba(255, 170, 30, 0.5),
      -1.8em -1.8em 0 0em rgba(255, 170, 30, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 170, 30, 0.7),
      1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 170, 30, 0.2),
      1.75em 1.75em 0 0em rgba(255, 170, 30, 0.2),
      0em 2.5em 0 0em rgba(255, 170, 30, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 170, 30, 0.2),
      -2.6em 0em 0 0em rgba(255, 170, 30, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 170, 30, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 170, 30, 0.5),
      1.8em -1.8em 0 0em rgba(255, 170, 30, 0.7), 2.5em 0em 0 0em #ffffff,
      1.75em 1.75em 0 0em rgba(255, 170, 30, 0.2),
      0em 2.5em 0 0em rgba(255, 170, 30, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 170, 30, 0.2),
      -2.6em 0em 0 0em rgba(255, 170, 30, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 170, 30, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 170, 30, 0.2),
      1.8em -1.8em 0 0em rgba(255, 170, 30, 0.5),
      2.5em 0em 0 0em rgba(255, 170, 30, 0.7), 1.75em 1.75em 0 0em #ffffff,
      0em 2.5em 0 0em rgba(255, 170, 30, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 170, 30, 0.2),
      -2.6em 0em 0 0em rgba(255, 170, 30, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 170, 30, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 170, 30, 0.2),
      1.8em -1.8em 0 0em rgba(255, 170, 30, 0.2),
      2.5em 0em 0 0em rgba(255, 170, 30, 0.5),
      1.75em 1.75em 0 0em rgba(255, 170, 30, 0.7), 0em 2.5em 0 0em #ffffff,
      -1.8em 1.8em 0 0em rgba(255, 170, 30, 0.2),
      -2.6em 0em 0 0em rgba(255, 170, 30, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 170, 30, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 170, 30, 0.2),
      1.8em -1.8em 0 0em rgba(255, 170, 30, 0.2),
      2.5em 0em 0 0em rgba(255, 170, 30, 0.2),
      1.75em 1.75em 0 0em rgba(255, 170, 30, 0.5),
      0em 2.5em 0 0em rgba(255, 170, 30, 0.7), -1.8em 1.8em 0 0em #ffffff,
      -2.6em 0em 0 0em rgba(255, 170, 30, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 170, 30, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 170, 30, 0.2),
      1.8em -1.8em 0 0em rgba(255, 170, 30, 0.2),
      2.5em 0em 0 0em rgba(255, 170, 30, 0.2),
      1.75em 1.75em 0 0em rgba(255, 170, 30, 0.2),
      0em 2.5em 0 0em rgba(255, 170, 30, 0.5),
      -1.8em 1.8em 0 0em rgba(255, 170, 30, 0.7), -2.6em 0em 0 0em #ffffff,
      -1.8em -1.8em 0 0em rgba(255, 170, 30, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 170, 30, 0.2),
      1.8em -1.8em 0 0em rgba(255, 170, 30, 0.2),
      2.5em 0em 0 0em rgba(255, 170, 30, 0.2),
      1.75em 1.75em 0 0em rgba(255, 170, 30, 0.2),
      0em 2.5em 0 0em rgba(255, 170, 30, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 170, 30, 0.5),
      -2.6em 0em 0 0em rgba(255, 170, 30, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}

.Toastify__toast-container {
  z-index: 999999 !important;
}

.bg-spinner-loader {
  background: #ffcb74;
}

.bt-spinner {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  background: transparent;
  border: 4px solid rgba(237, 237, 237, 0.2);
  border-top-color: rgba(255, 255, 255, 0.9);
  -webkit-animation: 1s spin linear infinite;
  animation: 1s spin linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.dine-in-card > div {
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.13);
}
.orderly-three-line {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
